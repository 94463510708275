.date-span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-family: 'Noto Sans', sans-serif;
  /* adiciona a margem à esquerda para telas maiores */
  margin-left: auto;
  top: -6px;
}

.date-span .btn {
  font-size: 1em;
}

@media (max-width: 992px) {
  .date-span {
    margin-right: 5px;
    margin-bottom: 5px;
    margin-left: 0;
    justify-content: start;
  }
}

@media (max-width: 768px) {
  .date-span {
    font-size: 0.875rem;
    margin-right: 5px;
    margin-bottom: 5px;
    color:purple;
    /* remove a margem à esquerda para telas menores */
    margin-left: 0;
    /* alinha os elementos à esquerda para telas menores */
    justify-content: flex-start;
  }
}

@media (max-width: 767px) {
  .date-span .btn {
    font-size: 0.975em;
  }
}

@media (max-width: 575px) {
  .date-span .btn {
    font-size: 0.875em;
  }
}



    
  
