.myStockSelector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 5px;
  font-family: 'Noto Sans', sans-serif;
}

.myStockSelector .btn {
  font-size: 1em;
}

.myStockSelector .btn:first-child{
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.myStockSelector .btn:last-child {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

  .btn-primary{
    --bs-btn-color: #4040ff !important;
    --bs-btn-bg: #fff !important;
    --bs-btn-border-color: #4040ff !important;
    --bs-btn-active-bg: #4040ff !important;
    --bs-btn-active-border-color: #4040ff !important;
    --bs-btn-active-font-weight:bold;
  

  }
  .btn-primary:hover{ 
    color: #fff !important ;
    background-color: #7F7FE8 !important;
    border-color: #fff !important;
  }

  @media (max-width: 767px) {
    .myStockSelector .btn {
      font-size: 0.875em;
      border-radius: 5px !important;
      top: -18px;
    }
  }
  
  @media (max-width: 575px) {
    .myStockSelector .btn {
      font-size: 0.75em;
      border-radius: 5px !important;
    }
  }
  
  @media (max-width: 768px) {
    .myStockSelector {
      flex-wrap: wrap;
    }
  }
  

  
  