.navbar-light .navbar-nav .nav-link {
  color: #4040FF;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #4040FF;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-light .navbar-brand {
  color: #4040ff;
  font-family: 'Noto Sans', sans-serif;
}

.navbar-light .navbar-toggler {
  color: #4040FF;
  border-color: #fff;
  font-family: 'Noto Sans', sans-serif;
}

.navbarButton{
  font-family: 'Noto Sans', sans-serif;
  color:#4040FF !important;
  font-size: 1em;
  text-align: center;
}

.navbarButton-primary {
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #4040ff !important;
  --bs-btn-font-family: 'Noto Sans', sans-serif;
  --bs-btn-active-color: #4040ff;
  --bs-nav-link-active-font-weight: bold;
}

.navbarButton:hover { /* Botões: Início, Sobre, Preços, Contato */
  color: #4040ff !important;
  font-weight: bold;
  background-color: #fff !important;
}

.profileButton {
  color: #4040ff !important;
  font-family: 'Noto Sans', sans-serif;
  margin: 0px !important;
  font-size: 1em;
}

.profileButton.active {
  font-weight: bold;
}

.custom-button { /* Botões: Entrar, cadastre-se */
  background-color: #fff !important;
  color: #4040ff !important;
  border-color: #4040FF !important;
  margin-right: 10px !important;
  font-family: 'Noto Sans', sans-serif;
  font-size: 1em;
}

.custom-button:hover {
  background-color: #7f7fe8 !important;
  color: #fff !important;
  border-color: #4040FF !important;
  margin-right: 10px !important;
  font-family: 'Noto Sans', sans-serif;
}

.color-nav {
  background-color: transparent !important;
}

.custom-button.active {
  font-weight: bold;
}
.navbarButton, .active {
  font-weight:900;
  text-decoration:underline ;
}

.navbarButton.selected{
  font-weight:900;
  text-decoration:underline ;
}

.dropdown-menu.show {
  width: fit-content;
}

@media screen and (max-width:758px) {
  .custom-button{
    float:inline-start;
  }
}

.navbarButton.selected {
  background-color: #4040ff;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

/* Estilos para telas móveis */

@media (max-width: 767px) {
  .navbar-nav {
    width: 100%;
  }
  
  .nav-item {
    width: 50%;
    text-align: center;
  }
  
  .home-nav .navbar-brand {
    order: 1; /* altera a ordem do logo em telas móveis */
  }
  
  .nav-item:nth-child(1) {
    order: 2; /* altera a ordem do primeiro botão em telas móveis */
  }
  
  .nav-item:nth-child(2) {
    order: 3; /* altera a ordem do segundo botão em telas móveis */
  }
  
  .navbar-toggler {
    order: 4; /* altera a ordem do botão hamburguer em telas móveis */
  }
  
  .navbar-collapse {
    order: 5; /* altera a ordem do menu deslizante em telas móveis */
  }

  .navbarButton {
    font-size: 0.800em !important;
    }

  .profileButton{
    font-size: 0.650em;
  }

  .profileButton.dropdown-item{
    width: 50px !important;
  }

  .dropdown-menu.show.dropdown-menu-end{
    width: fit-content !important;
  }
}
.close-icon {
  font-size: 24px; /* Tamanho do ícone */
  color: #333; /* Cor do ícone */
}
