.myUomSelector {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-family: 'Noto Sans', sans-serif;
  }
  
  .myUomSelector .btn {
    font-size: 1em;
  }

  @media (max-width: 767px) {
    .myUomSelector .btn {
      font-size: 0.875em;
    }
  }
  
  @media (max-width: 575px) {
    .myUomSelector .btn {
      font-size: 0.75em;
    }
  }
  