body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.bg-style {
  background-image: url('../img/liza-summer-pic.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100vw;
  min-height: 100% !important; 
  min-width: 100% !important; 
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.titulo-personalizado {
  font-size: 60px;
  font-family: 'Noto Sans', sans-serif;
  color: #4040ff;
  text-align: left;
}

.texto-personalizado {
  font-size: 30px;
  font-family: 'Noto Sans', sans-serif;
  color: #4040ff;
  text-align: left;
}

.my-card {
  background: transparent !important;
  border: none !important;
  box-shadow: none;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.navbarButton.active {
  font-weight:900;
  text-decoration:underline ;
}

@media (max-width: 767px) {
  body {
    overflow: auto;
    margin: 0;
    padding: 0;
  }
}





